import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

export class Footer extends Component  {
  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <>
        <footer className="footer">
          <ul className="social">
            <li>
              <a target="_blank" href="https://www.linkedin.com/in/andrewnewland/" rel="noopener noreferrer">
                <span className="hidden">LinkedIn</span>
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://github.com/anewland" rel="noopener noreferrer">
                <span className="hidden">GitHub</span>
                <i className="fa fa-github"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/andrewNewland/" rel="noopener noreferrer">
                <span className="hidden">Twitter</span>
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://instagram.com/adnewland/" rel="noopener noreferrer">
                <span className="hidden">Instagram</span>
                <i className="fa fa-instagram"></i>
              </a>
            </li>
          </ul>

          <p>&copy; { this.getYear() }. iThinkPixels.com. All Rights Reserved.</p>
        </footer>
      </>
    );
  }
}

export default Footer