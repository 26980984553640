import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

export class SectionTitle extends Component  {
  render() {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{ this.props.sectionTitle }</h2>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SectionTitle