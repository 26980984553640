import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

export class ProjectTile extends Component  {
  render() {
    return (
      <>
        <div className="col-3 project-tile">
            <div className="tile" id={ this.props.projid }>
              <a href={ this.props.website } target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + '/global/images/projects/'+this.props.projid+'.png'} className="project" alt={ this.props.title } /></a>
            </div>
            <h3>{ this.props.title }</h3>
        </div>
      </>
    );
  }
}

export default ProjectTile