import React from 'react';
// import { Link } from 'react-router-dom';

import SectionTitle from '../components/sectionTitle';
import ProjectTile from '../components/projectTile';

class Page extends React.Component {
  componentDidMount() {
    console.log('Home Page');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <section className="content">
          <div className="">
            <SectionTitle sectionTitle="About Me" />

            <div className="container about-me">
              <div className="row">
                <div className="col-8">
                  <p>Hi, I'm Andrew Newland and I am a husband, father, developer, podcaster, toy collector, comic book junkie,
                    Batman fanatic, Colorado Avalance supporter and all around nerd.</p>
                  <p>I found my passion for web development as a sophomore in high school, and pursued that interest to college,
                    eventually landing a position with Belo Interactive. While employed at Belo, I worked my way up from Web
                    Support Technician to Senior Designer of New Product Development within less than three years. Following my
                    time at Belo, I moved on to become a Front End Developer for Communications Corporation of America, and again
                    within less than three years I added Manager of Online Multimedia to my resume.</p>
                  <p>Currently, I am the Senior Application &amp; Software Developer for The University of St. Augustine (USAHS).
                    With the university, I have advanced my React.js and React Native skills along with continuing to push
                    my knowldge of vanilla JavaScript.</p>
                </div>

                <div className="col-4">
                  <img src={process.env.PUBLIC_URL + '/global/images/avatar.jpg'} className="aboutMe" alt="Andrew Newland" />
                </div>
              </div>
            </div>
          </div>

          <div className="pixel-background grey-background">
            <SectionTitle sectionTitle="Portfolio Projects" />

            <div className="container portfolio">
              <div className="row">
                <ProjectTile title="Podcast Bingo" projid="podbingo" website="https://podcastbingo.com" />
                <ProjectTile title="Toy Rewind Podcast" projid="toyrewind" website="https://toyrewindpodcast.com" />
                <ProjectTile title="American Plumbing Company" projid="apctyler" website="https://apctyler.com" />
                {/* <ProjectTile title="Hogville.net" projid="hogville" /> */}
                {/* <ProjectTile title="FearlessFriday.com" projid="fearless" /> */}
                {/* <ProjectTile title="InternetReputation.com" projid="internetrep" website="https://development.internetreputation.com" /> */}
                {/* <ProjectTile title="MarcaGlobal.us" projid="marca" /> */}
                <ProjectTile title="Come Follow Me FHE" projid="cfmfhe" website="https://comefollowmefhe.com" />
                <ProjectTile title="USAHS Re-entry Portal" projid="usahs-reentry" website="https://reentry.usa.edu" />
                <ProjectTile title="USAHS Re-entry Scanner App" projid="usahs-reentryapp" />
                <ProjectTile title="USAHS iLearn" projid="usahs-ilearn" website="https://ilearn.usa.edu" />
                <ProjectTile title="USAHS SP Portal" projid="usahs-spportal" website="https://spportal.usa.edu" />
                <ProjectTile title="USAHS Diversity Calendar" projid="usahs-diversity" website="https://diversity.usa.edu" />
                {/* <ProjectTile title="GlobalConstructionColorado.com" projid="globalconstruction" /> */}
                {/* <ProjectTile title="KapellaRoofing.com.com" projid="kapella" /> */}
                {/* <ProjectTile title="Dish.com College Football Schedules" projid="dishncaa" website="https://www.dish.com/programming/sports/college-football" /> */}
              </div>
            </div>
          </div>

          {/* <div className="">
            <SectionTitle sectionTitle="Social" />

            <div className="container">
              <div className="row">
              </div>
            </div>
          </div> */}
        </section>
      </>
    );
  }
}

export default Page;