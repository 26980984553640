import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Header extends Component  {
  about() {
    let about = document.querySelector('.about-me');
    const y = about.offsetTop - 120;

    window.scrollTo({
        top: y,
        behavior: 'smooth'
    })
  }

  portfolio() {
    let portfolio = document.querySelector('.portfolio');
    const y = portfolio.offsetTop - 120;

    window.scrollTo({
        top: y,
        behavior: 'smooth'
    })
  }

  render() {
    return (
      <>
        <header className="header">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <Link to="/">
                  <img src={process.env.PUBLIC_URL + '/global/images/itpLogo_fullHorz.png'} className="logo" alt="iThinkPixels :: Andrew Newland" />
                </Link>
              </div>

              <div className="col-6">
                <ul className="mainNav">
                  <li onClick={this.about}><Link to="/">About Me</Link></li>
                  <li onClick={this.portfolio}><Link to="/">My Work</Link></li>
                  {/* <li><Link to="/">Contact</Link></li> */}
                </ul>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header