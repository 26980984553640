import React from 'react';
import { Route } from 'react-router-dom';

// components
import Header from './components/header';
import Footer from './components/footer';

// pages
import Default from "./pages/homepage";
// import Pricing from "./pages/pricing";

export default function App() {
  return (
    <>
      <Header />

        <Route exact path="/" component={Default} />
        {/* <Route path="/pricing" component={Pricing} /> */}

      <Footer />
    </>
  );
}
